import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Grid,CardHeader } from '@material-ui/core';


class SearchToolbar extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.state = {
            searchKeyword: '',
        }
    }
      
    onSubmit( isReset ) {
        if( isReset){
            this.state.searchKeyword = '';
        }
        this.props.onSubmit( this.state.searchKeyword );
    }
    
    keyPress(e){
      if(e.keyCode === 13){
         this.onSubmit( false );
      }
   }
   
    render() {
    return (
       
        <div >
            <CardHeader title="Campaigns" subheader="Manage Campaigns > List/Search Campaign"/>
            <Grid container spacing={2} >   
            <Grid item md={6} xs={12} >
                <TextField fullWidth label="Search" name="title" margin="dense" variant="outlined" size="small"
                    value={this.state.searchKeyword}
                    onKeyDown={this.keyPress}
                    color="primary"
                    onChange={ ( event ) => { this.setState( {searchKeyword: event.target.value }) } }
                />
            </Grid>
            <Grid item md={6} xs={12} >
                <Button color="primary" variant="contained" style={{textTransform: 'capitalize'}} onClick={event => this.onSubmit(false)}>Search</Button>
                <Button color="other" variant="contained" style={{marginLeft:20, textTransform: 'capitalize'}}
                        onClick={event => this.onSubmit(true)}>Reset</Button>
            </Grid>
            </Grid> 
        </div>
    );
    }
}
SearchToolbar.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

export default SearchToolbar;

import React, { Component } from 'react';
import { SearchToolbar, CampaignsTable } from './components';
import Configuration from '../../service/configuration';
import DataServices from '../../service/data-services';
import CircularProgress from '@material-ui/core/CircularProgress';
import { orange } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


class CampaignList extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            campaignList: null,
            totalRecords: 0,
            rowsPerPage: 10,
            title: "",          
            errors:{ 
                error:false, 
                errorMessage:''
            },
            message: false,
            loading: false
        };
        
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.config = new Configuration( );
    this.dataService = new DataServices( );
    }
    componentDidMount() {
        this.state.rowsPerPage = this.dataService.getRowPerPage();
        this.getCampaignList( "", 0, this.state.rowsPerPage );//Math.pow(2,31)-1
    }
    
    onSubmit( title ){
        this.state.title = title;
        this.getCampaignList( this.state.title, 0, this.state.rowsPerPage );
    }
        
    onChangePage( first, max ){
        this.state.rowsPerPage = max;
        this.getCampaignList( this.state.title, first * max, max );
        this.dataService.setRowPerPage(max);
    }
    
    render() {
        const campaignList = this.state.campaignList;
        if(!campaignList) return null;
    
        return (
            <div style={{padding: 10, background: "white"}}>
              <SearchToolbar onSubmit={this.onSubmit}/>
              <div style={{marginTop: 12}}>
                <CampaignsTable campaigns={this.state.campaignList} 
                defaultRowsPerPage={this.state.rowsPerPage} defaultPage={0} 
                totalRecords={this.state.totalRecords}
                onChangePage={this.onChangePage} />
              </div>
                {/* loading circle */}
                {this.state.loading && <CircularProgress size={24} 
                    style={{color: orange[700],
                            position: 'absolute',
                            top: '70%',
                            left: '50%',
                            marginTop: -12,
                            marginLeft: -12,}}
                />}
                
                <Snackbar open={this.state.errors.error} autoHideDuration={5000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                     onClose={ ( event ) => { this.setState(Object.assign(this.state.errors,{ ['error']: false }));}}>
                    <MuiAlert severity="error" elevation={6} variant="filled"
                    onClose={ ( event ) => { this.setState(Object.assign(this.state.errors,{ ['error']: false }));}} >
                      {this.state.errors.errorMessage}
                    </MuiAlert>
                </Snackbar>
            </div>
        );
    }
        
    shouldComponentUpdate() {
        return true;
    }
        
    getCampaignList( title, first, max ) {
        if( this.state.message){return;}
        this.state.loading = true;
        this.state.errors.error = false;
        this.state.errors.errorMessage = null;
        this.setState(prevState => ({prevState}));
        
        this.dataService.retrieve(this.config.SERVICE_NAME_CAMPAIGN, 
        this.config.SERVICE_ACTION_CAMPAIGN_LIST_FOR_TABLE+"?title="+title + "&first="+first + "&max="+max ).then(items => {
            if(items) {
                let campaignList = [];
                var count = first;
                items[0].map(item => (
                        campaignList.push({
                            'no': ++count,
                            'id': item[0],
                    'title':item[1],
                    'issendimmediately': item[2], 
                    'scheduleat': item[3],
                    'type':item[4],
                    'status': item[5]
                        })
                ));
                this.setState({
                    campaignList: campaignList,
                    totalRecords:items[1][0],
                    loading: false
                });
            }else{
                this.state.loading = false;
                this.state.errors.error = true;
                this.state.errors.errorMessage = this.config.MESSAGE_REQUEST_TIMEOUT;
                this.setState(prevState => ({prevState}));
            }
        });
    }
}
export default CampaignList;

import React, { Component } from 'react';
import { SearchToolbar, ContentTable } from './components';
import Configuration from '../../service/configuration';
import DataServices from '../../service/data-services';
import CircularProgress from '@material-ui/core/CircularProgress';
import { orange } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Resources from '../../service/resources';


class AnnouncementList extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            contentList: [],
            totalRecords: 0,
            rowsPerPage: 10,
            errors:{ 
                error:false, 
                errorMessage:''
            },
            message: false,
            loading: false,
            search:{
                type:'Announcement',
                coverage:'',
                searchText:'',
                first:0,
                max:10,
                sort:null,
                asc: false
            }
        };
        
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.config = new Configuration( );
    this.dataService = new DataServices( );
    this.resources = new Resources( );
    this.onChangePublish = this.onChangePublish.bind(this);
    this.onCreate = this.onCreate.bind(this);
    }

    componentDidMount() {
        this.state.rowsPerPage = parseInt( this.dataService.getRowPerPage(), 10);
        this.state.search.max = this.state.rowsPerPage;
        this.getContentList( );//Math.pow(2,31)-1
    }
    
    onSubmit( title, c ){
        this.state.search.searchText = title;
        this.state.search.max = this.state.rowsPerPage;
        this.state.search.first = 0;
        this.state.search.coverage = c;
        this.getContentList( );
    }

    onChangePage( first, max, sortBy, asc  ){
        this.state.rowsPerPage = max;
        this.state.search.first =  first * max;
        this.state.search.max = max;

        if( sortBy != null && asc != null ){
            this.state.search.sort = sortBy;
            this.state.search.asc = asc;
        }

        this.getContentList(  );
        this.dataService.setRowPerPage(max);
    }

    onCreate(){
        window.location.href='/' + this.resources.APP_NAME + '/announcement_item/0/version';
    }

    render() {
        const contentList = this.state.contentList;
        if(!contentList) return null;
    
        return (
            <div style={{padding: 10, background: "white"}}>
              <SearchToolbar onSubmit={this.onSubmit} onCreate={this.onCreate}/>
              <div style={{marginTop: 12}}>
                <ContentTable contentList={this.state.contentList}
                defaultRowsPerPage={this.state.rowsPerPage} defaultPage={0}
                totalRecords={this.state.totalRecords}
                onChangePage={this.onChangePage}
                 onChangePublish={this.onChangePublish}/>
              </div>
                {/* loading circle */}
                {this.state.loading && <CircularProgress size={24} 
                    style={{color: orange[700],
                            position: 'absolute',
                            top: '70%',
                            left: '50%',
                            marginTop: -12,
                            marginLeft: -12,}}
                />}
                
                <Snackbar open={this.state.errors.error} autoHideDuration={5000} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                     onClose={ ( event ) => { this.setState(Object.assign(this.state.errors,{ ['error']: false }));}}>
                    <MuiAlert severity="error" elevation={6} variant="filled"
                    onClose={ ( event ) => { this.setState(Object.assign(this.state.errors,{ ['error']: false }));}} >
                      {this.state.errors.errorMessage}
                    </MuiAlert>
                </Snackbar>
            </div>
        );
    }
        
    shouldComponentUpdate() {
        return true;
    }
        
    getContentList( ) {
        if( this.state.message){return;}
        this.state.loading = true;
        this.state.errors.error = false;
        this.state.errors.errorMessage = null;
        this.setState(prevState => ({prevState}));

        console.log(this.state.search);
        this.dataService.retrievePOST(this.state.search,
        this.config.SERVICE_NAME_ANNOUNCEMENT + this.config.SERVICE_ACTION_LIST ).then(items => {

            if(items) {
                this.setState({
                    contentList: items["items"],
                    totalRecords:items["totalRecords"],
                    loading: false
                });
            }else{
                this.state.loading = false;
                this.state.errors.error = true;
                this.state.errors.errorMessage = this.config.MESSAGE_REQUEST_TIMEOUT;
                this.setState(prevState => ({prevState}));
            }
        });
    }

    onChangePublish( contentId ){
        this.state.loading = true;
        this.state.errors.error = false;
        this.state.errors.errorMessage = null;
        this.setState(prevState => ({prevState}));

        let changeStatus = {id: contentId, status:'active'};

        this.dataService.retrievePOST(changeStatus,
        this.config.SERVICE_NAME_ANNOUNCEMENT + this.config.SERVICE_ACTION_PUBLISH ).then(items => {

            if(items) {
                this.setState({
                    loading: false
                });
                this.getContentList(  );
            }else{
                this.state.loading = false;
                this.state.errors.error = true;
                this.state.errors.errorMessage = this.config.MESSAGE_REQUEST_TIMEOUT;
                this.setState(prevState => ({prevState}));
            }
        });
    }
}
export default AnnouncementList;

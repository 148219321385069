import React, { Component } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Configuration from '../../service/configuration';
import Resources from '../../service/resources';
import DataServices from '../../service/data-services';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Box
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { DateTimePicker, MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import CKEditor from 'ckeditor4-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { orange } from '@material-ui/core/colors';


class ContentItem extends Component {
    
  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
    this.validate = this.validate.bind(this);
    this.handleCampaignTypeChange = this.handleCampaignTypeChange.bind(this);
    
    this.state = {
        id: 0,
        content: '',
        version: '',
        coverage: '',
        startDatetime: new Date(),
        endDatetime: new Date(),
        status: 'inactive',
        type: '',
        typeLabel:'PDPA',
        coverageSelected: {id: 'per_trip', label:'Per Trip'},
        coverageType: [{id: 'per_trip', label:'Per Trip'}, {id: 'annual', label:'Annual'}],
        message: false,
        isDisable: false,
        dialogMessage:'',
        errors:{ content:null, version:null,sd:null,ed:null, error:false, errorMessage:''},
        loading:false,
        viewOnly:true
    };
    
    this.resources = new Resources();
    this.config = new Configuration();
    this.dataService = new DataServices( );

  }
    componentDidMount() {
        const id_param =this.props.match.params.id;
        const type_param =this.props.match.params.type;
        if( type_param ==='version')
            this.setState({viewOnly: false});

        this.getContent( id_param );
    }
    handleCampaignTypeChange = (event, values) => {
        this.setState({
            coverageSelected: values,
            coverage: values!=null? values.id : ''
        });
    }

    validate(){
        var checkstatus=true;
        if( !this.state.content ){
            this.state.errors.error = true;
            this.state.errors.errorMessage = 'Content should not be empty!';
            this.setState(prevState => ({prevState}));
            checkstatus= false;
        }else{
            this.state.errors.errorMessage = null;
            this.setState(prevState => ({prevState}));
        }

        if( !this.state.version ){
            this.state.errors.version = 'Required Field!';
            this.setState(prevState => ({prevState}));
            checkstatus= false;
        }else{
            this.state.errors.version = null;
            this.setState(prevState => ({prevState}));
        }

        if( !this.state.startDatetime ){
            this.state.errors.sd = 'Required Field!';
            this.setState(prevState => ({prevState}));
            checkstatus= false;
        }else{
            this.state.errors.sd = null;
            this.setState(prevState => ({prevState}));
        }

        if( !this.state.endDatetime ){
            this.state.errors.ed = 'Required Field!';
            this.setState(prevState => ({prevState}));
            checkstatus= false;
        }else{
            this.state.errors.sd = null;
            this.setState(prevState => ({prevState}));
        }

        return checkstatus;
     }

    onSave( status ){
        if( this.validate() ){

            if( this.state.message){return;}
            this.state.loading = true;
            this.state.errors.error = false;
            this.state.errors.errorMessage = null;
            this.setState(prevState => ({prevState}));

            let newVersion = {
            id:this.state.id,
            content: this.state.content,
            version: this.state.version,
            coverage: this.state.coverage,
            startDatetime: moment(this.state.startDatetime).format("YYYY-MM-DD"),
            endDatetime: moment(this.state.endDatetime).format("YYYY-MM-DD"),
            status: status,
            type: this.state.type
            };

            console.log( newVersion );
            this.dataService.retrievePOST( newVersion,
            this.config.SERVICE_NAME_CONTENT + this.config.SERVICE_ACTION_CREATE_VERSION ).then(items => {
                if(items) {
                    this.setState({
                        message: true,
                        isDisable: true,
                        dialogMessage: 'Content saved successful!',
                        loading: false
                    });
                }else{
                    this.state.loading = false;
                    this.state.errors.error = true;
                    this.state.errors.errorMessage = this.config.MESSAGE_REQUEST_TIMEOUT;
                    this.setState(prevState => ({prevState}));
                }
            });
          }

    }

      getContent( id ) {
          if( this.state.message){return;}
          this.state.loading = true;
          this.state.errors.error = false;
          this.state.errors.errorMessage = null;
          this.setState(prevState => ({prevState}));

          this.dataService.retrieve(this.config.SERVICE_NAME_CONTENT,
          this.config.SERVICE_ACTION_FIND+'/'+id ).then(items => {
              if(items) {
                var dtype = 'PDPA';
                if( items['type'] === 'pdpa')
                    dtype = 'PDPA';
                else if( items['type'] === 'declaration')
                    dtype = 'Declaration';
                else if( items['type'] === 'flight_status_disclaimer')
                    dtype = 'Flight Status Disclaimer';
                else if( items['type'] === 'question')
                    dtype = 'Marketing';
                else if( items['type'] === 'travel_advisory')
                    dtype = 'Travel Advisory';

                  this.setState({
                    id: items['id'],
                    content: items['content'],
                    version: items['version'],
                    coverage: items['coverage'],
                    startDatetime: moment(items['startDatetime']).format("YYYY-MM-DD"),
                    endDatetime: items['endDatetime'],
                    status: items['status'],
                    type: items['type'],
                    loading: false,
                    typeLabel: dtype,
                    coverageSelected: items['coverage']==='per_trip'? this.state.coverageType[0] : this.state.coverageType[1]
                  });
              }else{
                  this.state.loading = false;
                  this.state.errors.error = true;
                  this.state.errors.errorMessage = this.config.MESSAGE_REQUEST_TIMEOUT;
                  this.setState(prevState => ({prevState}));
              }
          });
      }

  
    render() {
    const classes = this.props;
    return (
        <div>
        <Card >
        <CardHeader title={this.state.viewOnly?'View' : 'Create New Version'} subheader={'Contents>List/Search '+this.state.typeLabel +(this.state.viewOnly?'>View ':'>Create a New Version ') +this.state.typeLabel}/>
        <form autoComplete="off" noValidate className={classes.root} >
        <CardContent>
          <Grid container spacing={3} >

            <Grid item md={12} xs={12}
              style={{marginTop:0,paddingTop:0, marginBottom:0,paddingBottom:0}} >
                <Typography gutterBottom variant="h6">
                    Content*:
                </Typography>
            </Grid>

            <Grid item md={12} xs={12} >
                <CKEditor
                    data={this.state.content}
                    onChange={ ( event ) => {  this.setState({content: event.editor.getData()}); } }
                    disabled={this.state.viewOnly}
                    config={{
                            toolbarGroups: [
                                { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
                                { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
                                { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                                { name: 'forms', groups: [ 'forms' ] },
                                { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                                { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
                                { name: 'links', groups: [ 'links' ] },
                                { name: 'insert', groups: [ 'insert' ] },
                                { name: 'styles', groups: [ 'styles' ] },
                                { name: 'others' },
                                { name: 'colors', groups: [ 'colors' ] },
                                { name: 'tools', groups: [ 'tools' ] },
                                { name: 'others', groups: [ 'others' ] },
                                { name: 'about', groups: [ 'about' ] }
                            ],
                            removeButtons: 'Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,RemoveFormat,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Flash,Smiley,SpecialChar,PageBreak,Iframe,ShowBlocks',
                            // extraPlugins: 'wordcount,notification',
                            wordcount: {
                                // Whether or not you want to show the Paragraphs Count
                                showParagraphs: true,

                                // Whether or not you want to show the Word Count
                                showWordCount: true,

                                // Whether or not you want to show the Char Count
                                showCharCount: true,

                                // Whether or not you want to count Spaces as Chars
                                countSpacesAsChars: true,

                                // Whether or not to include Html chars in the Char Count
                                countHTML: true,

                                // Maximum allowed Word Count, -1 is default for unlimited
                                maxWordCount: -1,

                                // Maximum allowed Char Count, -1 is default for unlimited
                                maxCharCount: 3500
                            }
                        }}
                />
            </Grid>

            <Grid item md={12} xs={12} >
              <TextField fullWidth
              label="Version"
              margin="dense"
                type="number"
              name="version"
                required value={this.state.version}
                variant="outlined"
                error={this.state.errors.version?true:false} helperText={this.state.errors.version}
                disabled={this.state.viewOnly}
                onChange={ ( event ) => {  this.setState({version: event.target.value}); } }
                />
            </Grid>

            <Grid item md={6} xs={12} >
                <Autocomplete
                    id="coverageType"
                    options={this.state.coverageType}
                    value ={this.state.coverageSelected}
                    autoHighlight
                    getOptionLabel={option => option.label}
                    size="small"
                    onChange={this.handleCampaignTypeChange}
                    style={{ width: '100%' }}
                    disabled={true}
                    renderInput={params => <TextField {...params} label="Coverage" variant="outlined" />}
                />
            </Grid>

            <Grid item md={12} xs={12} >

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container direction="column" justify="space-around">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="date-picker-inline"
                      required label="Eff Start Date"
                      value={this.state.startDatetime}
                      onChange={ ( event ) => {this.setState({startDatetime: event}); } }
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={this.state.viewOnly}
                    />
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      margin="normal"
                      id="date-picker-dialog"
                      required label="Eff End Date"
                      format="yyyy-MM-dd"
                      value={this.state.endDatetime}
                      onChange={ ( event ) => {this.setState({endDatetime: event}); } }
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={this.state.viewOnly}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
            </Grid>

            <Grid item md={12} xs={12} >
              <TextField
                fullWidth
                label="Type"
                margin="dense"
                name="type"
                value={this.state.typeLabel}
                error={this.state.errors.url?true:false} helperText={this.state.errors.url}
                disabled={true}
                variant="outlined" />
            </Grid>
            
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
        {this.state.viewOnly===false &&(
          <Button color="primary" variant="contained" style={{textTransform: 'capitalize'}} 
              onClick={event => this.onSave('inactive')}
                disabled={this.state.isDisable} >
            Save
          </Button>
        )}
        {this.state.viewOnly===false &&(
          <Button color="other" variant="contained" style={{textTransform: 'capitalize'}}
              onClick={event => this.onSave('active')}
                disabled={this.state.isDisable} >
            Publish
          </Button>
        )}


            {this.state.loading && <CircularProgress size={24}
               style={{color: orange[700],
                       position: 'absolute',
                       top: '100%',
                       left: '50%',
                       marginTop: -12,
                       marginLeft: -12}}
            />}

            <Button color="other" variant="contained" style={{textTransform: 'capitalize'}}
                onClick={event => this.props.history.goBack()}
                  disabled={this.state.isDisable} >
              Cancel
            </Button>

        </CardActions>
      </form>
      
    </Card>
    
    <Snackbar open={this.state.message} autoHideDuration={3000}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={ ( event ) => {
            this.setState({message:false});
            this.props.history.push('/' + this.resources.APP_NAME + '/content_list/' + this.state.type);
        }}>
        <MuiAlert severity="success" elevation={6} variant="filled"
        onClose={ ( event ) => {
            this.setState({message:false});
            this.props.history.push('/' + this.resources.APP_NAME + '/content_list/' + this.state.type);
        }}>
          {this.state.dialogMessage}
        </MuiAlert>
    </Snackbar>
    
     <Snackbar open={this.state.errors.error} autoHideDuration={6000} 
     anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         onClose={ ( event ) => { this.setState(Object.assign(this.state.errors,{ ['error']: false }));}}>
        <MuiAlert severity="error" elevation={6} variant="filled"
        onClose={ ( event ) => { this.setState(Object.assign(this.state.errors,{ ['error']: false }));}} >
          {this.state.errors.errorMessage}
        </MuiAlert>
    </Snackbar>
    
    </div>
  );
  
}

    shouldComponentUpdate() {
        return true;
    }
}

export default ContentItem;
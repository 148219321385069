import React, { Component } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Configuration from '../../service/configuration';
import Resources from '../../service/resources';
import DataServices from '../../service/data-services';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Box
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { DateTimePicker, MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import CKEditor from 'ckeditor4-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { orange } from '@material-ui/core/colors';


class AnnouncementItem extends Component {
    
  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
    this.validate = this.validate.bind(this);
    this.handleCampaignTypeChange = this.handleCampaignTypeChange.bind(this);
    
    this.state = {
        id: 0,
        announceMessage: '',
        startDatetime: new Date(),
        endDatetime: new Date(),
        status: 'inactive',
        message: false,
        isDisable: false,
        dialogMessage:'',
        errors:{ message:null,sd:null,ed:null, error:false, errorMessage:''},
        loading:false,
        viewOnly:true
    };
    
    this.resources = new Resources();
    this.config = new Configuration();
    this.dataService = new DataServices( );

  }
    componentDidMount() {
        const id_param =this.props.match.params.id;
        if( id_param > 0){
            const type_param =this.props.match.params.type;
            if( type_param ==='version')
                this.setState({viewOnly: false});
            this.getContent( id_param );
        }else{this.setState({viewOnly: false});}
    }

    handleCampaignTypeChange = (event, values) => {
        this.setState({
            coverageSelected: values,
            coverage: values!=null? values.id : ''
        });
    }

    validate(){
        var checkstatus=true;

        if( !this.state.announceMessage ){
            this.state.errors.message = 'Required Field!';
            this.setState(prevState => ({prevState}));
            checkstatus= false;
        }else{
            this.state.errors.message = null;
            this.setState(prevState => ({prevState}));
        }

        if( !this.state.startDatetime ){
            this.state.errors.sd = 'Required Field!';
            this.setState(prevState => ({prevState}));
            checkstatus= false;
        }else{
            this.state.errors.sd = null;
            this.setState(prevState => ({prevState}));
        }

        if( !this.state.endDatetime ){
            this.state.errors.ed = 'Required Field!';
            this.setState(prevState => ({prevState}));
            checkstatus= false;
        }else{
            this.state.errors.sd = null;
            this.setState(prevState => ({prevState}));
        }

        return checkstatus;
     }

    onSave( status ){
        if( this.validate() ){

            if( this.state.message){return;}
            this.state.loading = true;
            this.state.errors.error = false;
            this.state.errors.errorMessage = null;
            this.setState(prevState => ({prevState}));

            let newVersion = {
            message: this.state.announceMessage,
            startDatetime: moment(this.state.startDatetime).format("YYYY-MM-DD"),
            endDatetime: moment(this.state.endDatetime).format("YYYY-MM-DD"),
            status: status
            };

            console.log( newVersion );
            this.dataService.retrievePOST( newVersion,
            this.config.SERVICE_NAME_ANNOUNCEMENT + this.config.SERVICE_ACTION_CREATE_VERSION ).then(items => {
                if(items) {
                    this.setState({
                        message: true,
                        isDisable: true,
                        dialogMessage: 'Announcement saved successful!',
                        loading: false
                    });
                }else{
                    this.state.loading = false;
                    this.state.errors.error = true;
                    this.state.errors.errorMessage = this.config.MESSAGE_REQUEST_TIMEOUT;
                    this.setState(prevState => ({prevState}));
                }
            });
          }

    }

      getContent( id ) {
          if( this.state.message){return;}
          this.state.loading = true;
          this.state.errors.error = false;
          this.state.errors.errorMessage = null;
          this.setState(prevState => ({prevState}));

          this.dataService.retrieve(this.config.SERVICE_NAME_ANNOUNCEMENT,
          this.config.SERVICE_ACTION_FIND+'/'+id ).then(items => {
              if(items) {
                  this.setState({
                    id: items['id'],
                    announceMessage: items['message'],
                    startDatetime: moment(items['startDatetime']).format("YYYY-MM-DD"),
                    endDatetime: items['endDatetime'],
                    status: items['status'],
                    loading: false
                  });
              }else{
                  this.state.loading = false;
                  this.state.errors.error = true;
                  this.state.errors.errorMessage = this.config.MESSAGE_REQUEST_TIMEOUT;
                  this.setState(prevState => ({prevState}));
              }
          });
      }

  
    render() {
    const classes = this.props;
    return (
        <div>
        <Card >
        <form autoComplete="off" noValidate className={classes.root} >
        <CardContent>
          <Grid container spacing={3} >

            <Grid item md={12} xs={12} >
              <TextField fullWidth
              label="Message"
              margin="dense"
              name="message"
              multiline
              rows={3}
                required value={this.state.announceMessage}
                variant="outlined"
                error={this.state.errors.message?true:false} helperText={this.state.errors.message}
                disabled={this.state.viewOnly}
                onChange={ ( event ) => {  this.setState({announceMessage: event.target.value}); } }
                />
            </Grid>

            <Grid item md={12} xs={12} >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container direction="column" justify="space-around">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="date-picker-inline"
                      required label="Eff Start Date"
                      value={this.state.startDatetime}
                      onChange={ ( event ) => {this.setState({startDatetime: event}); } }
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={this.state.viewOnly}
                    />
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      margin="normal"
                      id="date-picker-dialog"
                      required label="Eff End Date"
                      format="yyyy-MM-dd"
                      value={this.state.endDatetime}
                      onChange={ ( event ) => {this.setState({endDatetime: event}); } }
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={this.state.viewOnly}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
        {this.state.viewOnly===false &&(
          <Button color="primary" variant="contained" style={{textTransform: 'capitalize'}} 
              onClick={event => this.onSave('inactive')}
                disabled={this.state.isDisable} >
            Save
          </Button>
        )}
        {this.state.viewOnly===false &&(
          <Button color="other" variant="contained" style={{textTransform: 'capitalize'}}
              onClick={event => this.onSave('active')}
                disabled={this.state.isDisable} >
            Publish
          </Button>
        )}


            {this.state.loading && <CircularProgress size={24}
               style={{color: orange[700],
                       position: 'absolute',
                       top: '100%',
                       left: '50%',
                       marginTop: -12,
                       marginLeft: -12}}
            />}

            <Button color="other" variant="contained" style={{textTransform: 'capitalize'}}
                onClick={event => this.props.history.goBack()}
                  disabled={this.state.isDisable} >
              Cancel
            </Button>

        </CardActions>
      </form>
      
    </Card>
    
    <Snackbar open={this.state.message} autoHideDuration={3000}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={ ( event ) => {
            this.setState({message:false});
            this.props.history.push('/' + this.resources.APP_NAME + '/announcement_list/');
        }}>
        <MuiAlert severity="success" elevation={6} variant="filled"
        onClose={ ( event ) => {
            this.setState({message:false});
            this.props.history.push('/' + this.resources.APP_NAME + '/announcement_list/');
        }}>
          {this.state.dialogMessage}
        </MuiAlert>
    </Snackbar>
    
     <Snackbar open={this.state.errors.error} autoHideDuration={6000} 
     anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         onClose={ ( event ) => { this.setState(Object.assign(this.state.errors,{ ['error']: false }));}}>
        <MuiAlert severity="error" elevation={6} variant="filled"
        onClose={ ( event ) => { this.setState(Object.assign(this.state.errors,{ ['error']: false }));}} >
          {this.state.errors.errorMessage}
        </MuiAlert>
    </Snackbar>
    
    </div>
  );
  
}

    shouldComponentUpdate() {
        return true;
    }
}

export default AnnouncementItem;
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from  '@material-ui/core/styles';
import { List, ListItem, Button, colors, Collapse } from '@material-ui/core';
import { Home as HomeIcon,
ExpandLess,
ExpandMore,
PowerSettingsNew as LogoutIcon,
Drafts as LeaveIcon, AlarmOn as AttendanceIcon,
ArrowRight as MenuClose,
ArrowDropDown as MenuOpen,
AttachMoney as PayrollIcon,
Business as OrganizationIcon,
AccountCircle as ProfileIcon,
Assignment as ReportIcon,
Settings as SettingsIcon,
TrackChanges,
CameraEnhance,
NotificationsActive,
Dashboard} from '@material-ui/icons';
import DataServices from '../../../../../../service/data-services';
import Configuration from '../../../../../../service/configuration';
import Resources from '../../../../../../service/resources';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useTranslation, withTranslation } from 'react-i18next'


const useStyles = makeStyles(theme => ({
  root: {margin:0,padding:0},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },  
  itemNested: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(4)
  },
  button: {
    color: colors.black,
    padding: '5px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
  
    <RouterLink {...props} />
        
  </div>
));

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

    const dataService = new DataServices( );
    const config = new Configuration();
    const resources = new Resources( );
    var auth = dataService.getAuthoritiesData( );

    const classes = useStyles();

    var t = props.lang;
    var i18n = props.i18n;

    const [openPayroll, setOpenPayroll] = React.useState(window.location.pathname.includes('pay'));
    const handleClickPayroll = () => {
      setOpenPayroll(!openPayroll);
    };
    const [openEmployee, setOpenEmployee] = React.useState(window.location.pathname.includes('employee'));
    const handleClickEmployee = () => {
        setOpenEmployee(!openEmployee);
    };
    const [openUpload, setOpenUpload] = React.useState(false);
    const handleClickUpload= () => {
        setOpenUpload(!openUpload);
    };
     const [openAttendence, setOpenAttendence] = React.useState(window.location.pathname.includes('time'));
        const handleClickAttendence= () => {
            setOpenAttendence(!openAttendence);
        };
    const [openLeave, setOpenLeave] = React.useState(window.location.pathname.includes('leave'));
    const handleClickLeave = () => {
        setOpenLeave(!openLeave);
    };
    const [openOrganization, setOpenOrganization] = React.useState(window.location.pathname.includes('organization'));
    const handleClickOrganization = () => {
        setOpenOrganization(!openOrganization);
    };
    const [openProfile, setOpenProfile] = React.useState(window.location.pathname.includes('profile'));
    const handleClickProfile = () => {
        setOpenProfile(!openProfile);
    };
    const [openReports, setOpenReports] = React.useState(window.location.pathname.includes('report'));
    const handleClickReports = () => {
        setOpenReports(!openReports);
    };
    const [openSettings, setOpenSettings] = React.useState(window.location.pathname.includes('system'));
    const handleClickSettings = () => {
        setOpenSettings(!openSettings);
    };
    const [openPeopleTracing, setOpenPeopleTracing] = React.useState(window.location.pathname.includes('tracing'));
    const handleClickPeopleTracing = () => {
        setOpenPeopleTracing(!openPeopleTracing);
    };
    const [openCamera, setOpenCamera] = React.useState(window.location.pathname.includes('camera'));
    const handleClickCamera = () => {
        setOpenCamera(!openCamera);
    };
    const [openAlert, setOpenAlert] = React.useState(window.location.pathname.includes('alert'));
    const handleClickAlert = () => {
        setOpenAlert(!openAlert);
    };

  return (
          <div>
        {( auth.includes('ORGANIZATION_WRITE') && config.MODULE_ENTERPRISE===true ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters >
                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to={'/enterprise_dashboard'} >
              <div className={classes.icon}>{<Dashboard />}</div>
                {t('menu.dashboard')}
              </Button>
            </ListItem>
        </List>
        }

        {(auth.includes('ORGANIZATION_WRITE') && config.MODULE_ENTERPRISE===true) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickPeopleTracing();}}>
              {openPeopleTracing ? <MenuOpen /> : <MenuClose />}
              <Button activeClassName={classes.active} className={classes.button} >
                <div className={classes.icon}>{<TrackChanges />}</div>
                {t('menu.people_tracing')}
              </Button>
            </ListItem>

            <Collapse in={openPeopleTracing} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/tracing_list'} >
                        {t('menu.tracing.people_list')}
                      </Button>
                    </ListItem>
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/tracing_output'} >
                        {t('menu.tracing.output')}
                      </Button>
                    </ListItem>
                </List>
            </Collapse>
        </List>
        }

        {(auth.includes('ORGANIZATION_WRITE') && config.MODULE_ENTERPRISE===true) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickCamera();}}>
              {openCamera ? <MenuOpen /> : <MenuClose />}
              <Button activeClassName={classes.active} className={classes.button} >
                <div className={classes.icon}>{<CameraEnhance />}</div>
                {t('menu.camera')}
              </Button>
            </ListItem>

            <Collapse in={openCamera} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/camera_list'} >
                        {t('menu.camera.list')}
                      </Button>
                    </ListItem>
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/camera_list_preview'} >
                        {t('menu.camera.list_preview')}
                      </Button>
                    </ListItem>
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/camera_logs'} >
                        {t('menu.camera.logs')}
                      </Button>
                    </ListItem>
                </List>
            </Collapse>
        </List>
        }
         {( auth.includes('ORGANIZATION_WRITE') && config.MODULE_ENTERPRISE===true ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters onClick={event =>{handleClickEmployee();}}>
              {openEmployee ? <MenuOpen /> : <MenuClose />}
                <Button activeClassName={classes.active} className={classes.button} >
                  <div className={classes.icon}>{<AssignmentIndIcon />}</div>
                  {t('menu.people')}
                </Button>
              </ListItem>
              <Collapse in={openEmployee} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={'/employee_list'} >
                          {t('menu.people.list')}
                        </Button>
                      </ListItem>

                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/employee_create'} >
                          {t('menu.people.add')}
                        </Button>
                      </ListItem>
                  </List>
              </Collapse>
          </List>
         }
         {( auth.includes('ORGANIZATION_WRITE') && config.MODULE_ENTERPRISE===true ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters onClick={event =>{handleClickAlert();}}>
              {openAlert ? <MenuOpen /> : <MenuClose />}
                <Button activeClassName={classes.active} className={classes.button} >
                  <div className={classes.icon}>{<NotificationsActive />}</div>
                  {t('menu.alertCenter')}
                </Button>
              </ListItem>
              <Collapse in={openAlert} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={'/alert_logs'} >
                          {t('menu.alertCenter.logs')}
                        </Button>
                      </ListItem>

                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/alert_settings'} >
                          {t('menu.alertCenter.settings')}
                        </Button>
                      </ListItem>
                  </List>
              </Collapse>
          </List>
         }

        {( auth.includes('ORGANIZATION_WRITE') && config.MODULE_ENTERPRISE===true ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters >
                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    onClick={event =>{dataService.removeTokenCookie();}}
                >
              <div className={classes.icon}>{<SettingsIcon />}</div>
                {t('menu.settings')}
              </Button>
            </ListItem>
        </List>
        }

    {( (auth.includes('ORGANIZATION_READ') || auth.includes('MANAGER_READ')) && config.MODULE_HRM===true ) &&
    <List {...rest} className={clsx(classes.root, className)}>
        <ListItem className={classes.item} disableGutters onClick={event =>{handleClickOrganization();}}>
          {openOrganization ? <MenuOpen /> : <MenuClose />}
          <Button activeClassName={classes.active} className={classes.button} >
            <div className={classes.icon}>{<OrganizationIcon />}</div>
            Organization
          </Button>
        </ListItem>

        <Collapse in={openOrganization} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItem className={classes.itemNested} disableGutters >
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                  to={'/organization_admin_dashboard'} >
                    {t('menu.dashboard')}
                  </Button>
                </ListItem>
                <ListItem className={classes.itemNested} disableGutters >
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                  to={'/organization_info'} >
                    Organization info
                  </Button>
                </ListItem>
                <ListItem className={classes.itemNested} disableGutters >
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                  to={'/organization_department'} >
                    Department
                  </Button>
                </ListItem>
                <ListItem className={classes.itemNested} disableGutters >
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                  to={'/organization_qualification_setup'} >
                    Qualifications setup
                  </Button>
                </ListItem>
                 <ListItem className={classes.itemNested} disableGutters >
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                   to={'/organization_job_title'} >
                    Job details setup
                  </Button>
                 </ListItem>
            </List>
        </Collapse>
    </List>
    }
        { config.MODULE_HRM===true &&
            <List {...rest} className={clsx(classes.root, className)}>
                <ListItem className={classes.item} disableGutters onClick={event =>{handleClickProfile();}}>
                {openProfile ? <MenuOpen /> : <MenuClose />}
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<ProfileIcon />}</div>
                    Personal profile
                  </Button>
                </ListItem>
                <Collapse in={openProfile} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/profile_dashboard'} >
                            My dashboard
                          </Button>
                        </ListItem>
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active}
                          className={classes.button}
                          component={CustomRouterLink}
                          to={'/profile_information'} >
                            Basic information
                          </Button>
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        }
         {( (auth.includes('ORGANIZATION_WRITE') || auth.includes('MANAGER_WRITE') || auth.includes('SUPERVISOR_WRITE')) && config.MODULE_HRM===true ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters onClick={event =>{handleClickEmployee();}}>
              {openEmployee ? <MenuOpen /> : <MenuClose />}
                <Button activeClassName={classes.active} className={classes.button} >
                  <div className={classes.icon}>{<AssignmentIndIcon />}</div>
                  Employees
                </Button>
              </ListItem>
              <Collapse in={openEmployee} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={'/employee_list'} >
                          Employees
                        </Button>
                      </ListItem>

                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/employee_create'} >
                          New employee
                        </Button>
                      </ListItem>

                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={'/employee_upload'} >
                        Import employees
                        </Button>
                      </ListItem>
                  </List>
              </Collapse>
          </List>
         }
            {/*<List {...rest} className={clsx(classes.root, className)}>
                  <ListItem className={classes.item} disableGutters onClick={event =>{handleClickUpload();}}>
                  {openUpload ? <MenuOpen /> : <MenuClose />}
                    <Button activeClassName={classes.active} className={classes.button} >
                      <div className={classes.icon}>{<CloudUploadIcon />}</div>
                      Upload
                    </Button>
                  </ListItem>

                  <Collapse in={openUpload} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active}
                            className={classes.button}
                            component={CustomRouterLink}
                            to={'/employee_upload'} >
                            Upload Employee
                            </Button>
                          </ListItem>
                      </List>
                  </Collapse>
            </List>*/}

        { config.MODULE_HRM===true &&
           <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters onClick={event =>{handleClickAttendence();}}>
                {openAttendence ? <MenuOpen /> : <MenuClose />}
                <Button activeClassName={classes.active} className={classes.button} >
                  <div className={classes.icon}>{<AttendanceIcon />}</div>
                  Time management
                </Button>
              </ListItem>

              <Collapse in={openAttendence} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={'/time_attendance'} >
                        Attendance List
                        </Button>
                      </ListItem>
                      {/*{(auth.includes('ORGANIZATION_WRITE') || auth.includes('MANAGER_WRITE')) &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active}
                            className={classes.button}
                            component={CustomRouterLink}
                            to={'/not-found'} >
                            Attendence zone restriction
                            </Button>
                          </ListItem>
                      }*/}
                      {/*<ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={'/not-found'} >
                        Time sheets
                        </Button>
                      </ListItem>
                      */}
                      {(auth.includes('ORGANIZATION_WRITE') || auth.includes('MANAGER_WRITE')) &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active}
                          className={classes.button}
                          component={CustomRouterLink}
                          to={'/time_working_days'} >
                            Working days
                          </Button>
                        </ListItem>
                      }
                      {(auth.includes('ORGANIZATION_WRITE') || auth.includes('MANAGER_WRITE')) &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active}
                          className={classes.button}
                          component={CustomRouterLink}
                          to={'/time_holidays'} >
                            Holidays
                          </Button>
                        </ListItem>
                      }
                  </List>
              </Collapse>
            </List>
        }

      { config.MODULE_HRM===true &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickLeave();}}>
              {openLeave ? <MenuOpen /> : <MenuClose />}
              <Button activeClassName={classes.active} className={classes.button} >
                <div className={classes.icon}>{<LeaveIcon />}</div>
                Leave
              </Button>
            </ListItem>
        
            <Collapse in={openLeave} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                    <ListItem className={classes.itemNested} disableGutters >
                    <Button activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={'/apply_leave'}>
                        Apply leave
                      </Button>
                    </ListItem>

                    <ListItem className={classes.itemNested} disableGutters >
                    <Button activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={'/leave_entitlement'}>
                        Leave entitlement
                      </Button>
                    </ListItem>

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to={'/manage_leave'} >
                        Manage leave
                      </Button>
                    </ListItem>
                    {(auth.includes('ORGANIZATION_WRITE') || auth.includes('MANAGER_WRITE') || auth.includes('SUPERVISOR_WRITE')) &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active}
                          className={classes.button}
                          component={CustomRouterLink}
                          to={'/leave_settings'} >
                            Leave settings
                          </Button>
                        </ListItem>
                    }
                </List>
            </Collapse>
        </List>
      }
        { config.MODULE_HRM===true &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickPayroll();}}>
              {openPayroll ? <MenuOpen /> : <MenuClose />}
              <Button activeClassName={classes.active} className={classes.button} >
                <div className={classes.icon}>{<PayrollIcon />}</div>
                Payroll
              </Button>
            </ListItem>
        
            <Collapse in={openPayroll} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {(auth.includes('ORGANIZATION_WRITE') || auth.includes('MANAGER_WRITE')) &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/pay_packages'} >
                            Salary packages
                          </Button>
                        </ListItem>
                    }
                    {(auth.includes('ORGANIZATION_WRITE') || auth.includes('MANAGER_WRITE')) &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/payroll_setup/deductions'} >
                            Deductions
                          </Button>
                        </ListItem>
                    }
                    {(auth.includes('ORGANIZATION_WRITE') || auth.includes('MANAGER_WRITE')) &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/payroll_setup/calculation_items'} >
                            Payroll columns
                          </Button>
                        </ListItem>
                    }
                    {(auth.includes('ORGANIZATION_WRITE') || auth.includes('MANAGER_WRITE')) &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/payroll_setup/payslip_template'} >
                            Payslip template
                          </Button>
                        </ListItem>
                    }
                    {(auth.includes('ORGANIZATION_WRITE') || auth.includes('MANAGER_WRITE')) &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/payroll_process'} >
                            Payroll processing
                          </Button>
                        </ListItem>
                    }
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/payroll_mine'} >
                        My payslips
                      </Button>
                    </ListItem>
                </List>
            </Collapse>
        </List>
        }
        { config.MODULE_HRM===true &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickReports();}}>
              {openReports ? <MenuOpen /> : <MenuClose />}
              <Button activeClassName={classes.active} className={classes.button} >
                <div className={classes.icon}>{<ReportIcon />}</div>
                Reporting
              </Button>
            </ListItem>

            <Collapse in={openReports} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {(auth.includes('ORGANIZATION_WRITE') || auth.includes('MANAGER_WRITE') || auth.includes('SUPERVISOR_WRITE')) &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/admin_reports'} >
                            Download admin reports
                          </Button>
                        </ListItem>
                    }
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/user_reports'} >
                        Download my reports
                      </Button>
                    </ListItem>
                </List>
            </Collapse>
        </List>
        }

    {(auth.includes('ORGANIZATION_WRITE') && config.MODULE_HRM===true) &&
    <List {...rest} className={clsx(classes.root, className)}>
        <ListItem className={classes.item} disableGutters onClick={event =>{handleClickSettings();}}>
          {openSettings ? <MenuOpen /> : <MenuClose />}
          <Button activeClassName={classes.active} className={classes.button} >
            <div className={classes.icon}>{<SettingsIcon />}</div>
            System
          </Button>
        </ListItem>

        <Collapse in={openSettings} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItem className={classes.itemNested} disableGutters >
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                  to={'/system_settings'} >
                    Settings
                  </Button>
                </ListItem>
                <ListItem className={classes.itemNested} disableGutters >
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                  to={'/system_cctv'} >
                    CCTV Settings
                  </Button>
                </ListItem>
            </List>
        </Collapse>
    </List>
    }

        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters >
                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    onClick={event =>{dataService.removeTokenCookie();}}
                >
              <div className={classes.icon}>{<LogoutIcon />}</div>
                {t('menu.logout')}
              </Button>
            </ListItem>
        </List>
    </div>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

//export default SidebarNav;
export default (props) => {
    const { t, i18n } = useTranslation();
    return (<SidebarNav lang={t} i18n={i18n} props{... props} />)
}

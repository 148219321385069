import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import Resources from '../../../../service/resources';

//import { getInitials } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 750
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  table: {
    minWidth: 750
  }
}));

const CampaignsTable = props => {
    const { className, campaigns, defaultRowsPerPage, defaultPage, onChangePage, totalRecords, ...rest } = props;

    const resources = new Resources( );
    const classes = useStyles();

    const [selectedCampaigns, setSelectedCampaigns] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [page, setPage] = useState(defaultPage);

  const handlePageChange = (event, newPage) => {
    //console.log('handlePageChange' + newPage);
    onChangePage(newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    //console.log('handleRowsPerPageChange');
    setRowsPerPage(parseInt(event.target.value, 10));
    onChangePage( 0, parseInt(event.target.value, 10));
    setPage( defaultPage );
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
      
        <PerfectScrollbar>
          {/*<div className={classes.inner}></div>*/}
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width:'100px'}}>No</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>ScheduleAt</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { campaigns.slice(0, rowsPerPage).map(campaign => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={campaign.id}
                    selected={selectedCampaigns.indexOf(campaign.id) !== -1}
                    onClick={(e)=> { window.location.href='/' + resources.APP_NAME + '/campaign_edit/' + campaign.id }}
                  >
                    <TableCell>{campaign.no}</TableCell>
                    <TableCell>
                    {campaign.title}
                    </TableCell>
                    <TableCell>{campaign.type}</TableCell>
                    <TableCell>
                      { campaign.scheduleat===null ? 'Send Immediately' : moment(campaign.scheduleat).format('DD/MM/YYYY hh:mm a') }
                    </TableCell>
                    <TableCell>{campaign.status}</TableCell>
                    <TableCell> <Tooltip title="Edit" arrow><Edit color="secondary" /></Tooltip> </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
          </PerfectScrollbar>
        
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalRecords}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </CardActions>
    </Card>
  );
};

CampaignsTable.propTypes = {
  className: PropTypes.string,
  campaigns: PropTypes.array.isRequired,
  totalRecords: PropTypes.number,
  onChangePage: PropTypes.func,
  defaultRowsPerPage: PropTypes.number.isRequired,
  defaultPage: PropTypes.number.isRequired
};

export default CampaignsTable;

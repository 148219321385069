import React, { forwardRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from  '@material-ui/core/styles';
import { Avatar, CardHeader, Tooltip } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import cookie from 'react-cookies';
import Configuration from '../../../../../../service/configuration';
import DataServices from '../../../../../../service/data-services';
import Resources from '../../../../../../service/resources';
import { NavLink as RouterLink } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginTop: 56,
    /*flexDirection: 'column',
    alignItems: 'left',
    minHeight: 'fit-content'*/
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));
const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >

    <RouterLink {...props} />

  </div>
));
const Profile = props => {
    const { className, ...rest } = props;
    const config = new Configuration();
    const classes = useStyles();
    const dataService = new DataServices( );
    var userData = dataService.getCurrentUserData();
    const resources = new Resources( );


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
    <CardHeader
        title={userData['name']}
        subheader={userData['roleName']}
        avatar={
            <Tooltip title="My Profile" arrow>
                <Avatar
                     alt={userData['name']}
                     src={ resources.BACKEND_SIDE_BASE_URL+"/v1/recogser/recognition/download_profile/"+userData['facialGUID']}
                    component={CustomRouterLink}
                    to={'/profile_information'}
                />
            </Tooltip>
        } />
      {/* 
    <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={user.avatar}
        to="/settings"
      />
      <Typography
        className={classes.name}
        variant="h4"
      >
        {user.name}
      </Typography>
      <Typography variant="body2">{user.bio}</Typography>
       */}    
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;

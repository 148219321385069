import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout} from './layouts';
import {NotFound as NotFoundView} from './views';
import Resources from './service/resources';
import './i18n'


const EmployeesList = lazy(() => import('./views/Employees/EmployeesList'));
const EmployeeItem = lazy(() => import('./views/Employees/EmployeeItem'));
const EmployeeEdit = lazy(() => import('./views/Employees/EmployeeEdit'));
const EmployeeCreate = lazy(() => import('./views/Employees/EmployeeCreate'));
const AttendanceList = lazy(() => import('./views/AttendanceList'));
const EmployeeUpload = lazy(() => import('./views/Upload/EmployeeUpload'));
const Login = lazy(() => import('./views/Login'));
const LeaveList = lazy(() => import('./views/Leave/Manage/LeaveList'));
const ApplyLeave = lazy(() => import('./views/Leave/ApplyLeave'));
const Entitlement = lazy(() => import('./views/Leave/Entitlement'));
const LeaveSettings = lazy(() => import('./views/Leave/Settings/LeaveSettings'));
const SalaryComponents = lazy(() => import('./views/Payroll/SalaryComponents'));
const PayrollSettings = lazy(() => import('./views/Payroll/PayrollSettings'));
const PayrollProcess = lazy(() => import('./views/Payroll/PayrollProcess'));
const PayslipDownload = lazy(() => import('./views/Payroll/PayslipDownload'));
const QualificationsItem = lazy(() => import('./views/Qualifications/QualificationsItem'));
const AdminDashboard = lazy(() => import('./views/AdminDashboard/AdminDashboard'));
const EmployeeDashboard = lazy(() => import('./views/EmployeeDashboard/EmployeeDashboard'));
const DepartmentItem = lazy(() => import('./views/Department/DepartmentItem'));
const AttendanceEdit = lazy(() => import('./views/AttendanceEdit/AttendanceEdit'));
const AdminReports = lazy(() => import('./views/AdminReports/AdminReports'));
const HolidayItem = lazy(() => import('./views/Holiday/HolidayItem'));
const WorkingDaysItem = lazy(() => import('./views/WorkingDays/WorkingDaysItem'));
const UserReports = lazy(() => import('./views/UserReports/UserReports'));
const JobDetailsSetup = lazy(() => import('./views/JobDetailsSetup/JobDetailsSetup'));
const Register = lazy(() => import('./views/Register/Register'));
const Validate = lazy(() => import('./views/Register/Validate'));
const Organization = lazy(() => import('./views/Organization/Organization'));
const FtpUser = lazy(() => import('./views/Settings/FtpUser'));
const SystemSettings = lazy(() => import('./views/Settings/SystemSettings'));
const PeopleTracingList = lazy(() => import('./views/Enterprise/PeopleTracing'));
const TracingLogs = lazy(() => import('./views/Enterprise/TracingLogs'));
const CameraListPreview = lazy(() => import('./views/Enterprise/Camera/CameraListPreview'));
const CameraLivePreview = lazy(() => import('./views/Enterprise/Camera/CameraLivePreview'));
const CameraLogs = lazy(() => import('./views/Enterprise/Camera/CameraLogs'));
const CameraList = lazy(() => import('./views/Enterprise/Camera/CameraList'));
const Person = lazy(() => import('./views/Enterprise/Person'));
const Dashboard = lazy(() => import('./views/Enterprise/Dashboard'));

const Routes = () => {

    const resources = new Resources( );

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to={'login'}
      />
      <Redirect
        exact
        from={'/' }
        to={'login'}
      />
      <RouteWithLayout
        component={Login}
        exact
        layout={MinimalLayout}
        path={'/login'}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MainLayout}
        path={'not-found'}
      />
       <RouteWithLayout
          component={EmployeesList}
          exact
          layout={MainLayout}
          path={'/employee_list'}
        />
         <RouteWithLayout
          component={EmployeeItem}
          exact
          layout={MainLayout}
          path={'/employee_new/:id'}
        />
        <RouteWithLayout
          component={EmployeeEdit}
          exact
          layout={MainLayout}
          path={'/employee_edit/:employee/:user/:facial'}
        />
        
         <RouteWithLayout
          component={EmployeeUpload}
          exact
          layout={MainLayout}
          path={'/employee_upload'}
        />
        <RouteWithLayout
          component={AttendanceList}
          exact
          layout={MainLayout}
          path={'/time_attendance'}
        />

        <RouteWithLayout
          component={LeaveList}
          exact
          layout={MainLayout}
          path={'/manage_leave'}
        />
        <RouteWithLayout
          component={LeaveSettings}
          exact
          layout={MainLayout}
          path={'/leave_settings'}
        />
        <RouteWithLayout
          component={ApplyLeave}
          exact
          layout={MainLayout}
          path={'/apply_leave'}
        />
        <RouteWithLayout
          component={Entitlement}
          exact
          layout={MainLayout}
          path={'/leave_entitlement'}
        />
        <RouteWithLayout
          component={SalaryComponents}
          exact
          layout={MainLayout}
          path={'/pay_packages'}
        />
        <RouteWithLayout
          component={PayrollSettings}
          exact
          layout={MainLayout}
          path={'/payroll_setup/:type'}
        />
        <RouteWithLayout
          component={PayrollProcess}
          exact
          layout={MainLayout}
          path={'/payroll_process'}
        />
         <RouteWithLayout
          component={QualificationsItem}
          exact
          layout={MainLayout}
          path={'/organization_qualification_setup'}
         />
         <RouteWithLayout
          component={EmployeeCreate}
          exact
          layout={MainLayout}
          path={'/employee_create'}
         />
         <RouteWithLayout
          component={AdminDashboard}
          exact
          layout={MainLayout}
          path={'/organization_admin_dashboard'}
         />
         <RouteWithLayout
          component={EmployeeDashboard}
          exact
          layout={MainLayout}
          path={'/profile_dashboard'}
         />
         <RouteWithLayout
            component={DepartmentItem}
            exact
            layout={MainLayout}
            path={'/organization_department'}
         />
         <RouteWithLayout
           component={JobDetailsSetup}
            exact
            layout={MainLayout}
            path={'/organization_job_title'}
         />
         <RouteWithLayout
           component={AttendanceEdit}
           exact
           layout={MainLayout}
           path={'/attendance_edit/:id'}
         />
         <RouteWithLayout
           component={EmployeeEdit}
           exact
           layout={MainLayout}
           path={'/profile_information'}
         />
         <RouteWithLayout
           component={AdminReports}
           exact
           layout={MainLayout}
           path={'/admin_reports'}
         />
         <RouteWithLayout
            component={HolidayItem}
             exact
             layout={MainLayout}
             path={'/time_holidays'}
         />
         <RouteWithLayout
          component={WorkingDaysItem}
           exact
           layout={MainLayout}
           path={'/time_working_days'}
         />
         <RouteWithLayout
           component={UserReports}
           exact
           layout={MainLayout}
           path={'/user_reports'}
         />
         <RouteWithLayout
           component={PayslipDownload}
           exact
           layout={MainLayout}
           path={'/payroll_mine'}
         />
         <RouteWithLayout
            component={Register}
            exact
            layout={MinimalLayout}
            path={'/sign-up'}
         />
         <RouteWithLayout
            component={Validate}
            exact
            layout={MinimalLayout}
            path={'/validate/:id'}
         />
         <RouteWithLayout
           component={Organization}
           exact
           layout={MainLayout}
           path={'/organization_info'}
         />
         <RouteWithLayout
           component={FtpUser}
           exact
           layout={MainLayout}
           path={'/system_cctv'}
         />
         <RouteWithLayout
           component={SystemSettings}
           exact
           layout={MainLayout}
           path={'/system_settings'}
         />
         <RouteWithLayout
           component={PeopleTracingList}
           exact
           layout={MainLayout}
           path={'/tracing_list'}
         />
         <RouteWithLayout
           component={TracingLogs}
           exact
           layout={MainLayout}
           path={'/tracing_output'}
         />
         <RouteWithLayout
           component={TracingLogs}
           exact
           layout={MainLayout}
           path={'/tracing_output/:person'}
         />
         <RouteWithLayout
           component={CameraListPreview}
           exact
           layout={MainLayout}
           path={'/camera_list_preview'}
         />
         <RouteWithLayout
            component={CameraLivePreview}
            exact
            layout={MinimalLayout}
            path={'/camera_live_view/:id/:selected'}
         />
         <RouteWithLayout
           component={CameraLogs}
           exact
           layout={MainLayout}
           path={'/camera_logs'}
         />
         <RouteWithLayout
           component={CameraList}
           exact
           layout={MainLayout}
           path={'/camera_list'}
         />
         <RouteWithLayout
           component={Person}
           exact
           layout={MainLayout}
           path={'/person_profile/:id'}
         />
         <RouteWithLayout
           component={Dashboard}
           exact
           layout={MainLayout}
           path={'/enterprise_dashboard'}
         />
          <RouteWithLayout
            component={Login}
            exact
            layout={MinimalLayout}
            path={'/login/:token/:expire/:refresh'}
          />
      <Redirect to={'login'} />
    </Switch>
  );
};

export default Routes;

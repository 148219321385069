import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Button
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import Resources from '../../../../service/resources';
import { Link } from 'react-router-dom';
import { colors } from '@material-ui/core';



const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 750
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  table: {
    minWidth: 750
  }
}));

const ContentTable = props => {
    const { className, contentList, defaultRowsPerPage, defaultPage, onChangePage, totalRecords, onChangePublish, ...rest } = props;

    const resources = new Resources( );
    const classes = useStyles();

    const [selectedCampaigns, setSelectedCampaigns] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [page, setPage] = useState(defaultPage);
    const [asc, setAsc] = useState(false);

  const handlePageChange = (event, newPage) => {
    onChangePage(newPage, rowsPerPage, null, null);
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onChangePage( 0, parseInt(event.target.value, 10), null, null);
    setPage( defaultPage );
  };

    const handleRowClick = keyword =>{
        setAsc(!asc);
        onChangePage( page, rowsPerPage, keyword, asc);
    };

    const handlePublishClick = contentId =>{
        onChangePublish( contentId);
    };

    const handleViewClick = contentId =>{
        window.location.href='/' + resources.APP_NAME + '/content_item/'+contentId+'/view';
    };

    const handleVersionClick = contentId =>{
        window.location.href='/' + resources.APP_NAME + '/content_item/'+contentId+'/version';
    };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
      
        <PerfectScrollbar>
          {/*<div className={classes.inner}></div>*/}
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell><Link underline='hover' onClick={event => handleRowClick('version')} title="sort">Version</Link></TableCell>
                  <TableCell><Link underline='hover' onClick={event => handleRowClick('createdOn')} title="sort">Created Date</Link></TableCell>
                  <TableCell><Link underline='hover' onClick={event => handleRowClick('effStartDatetime')} title="sort">Eff Start Date</Link></TableCell>
                  <TableCell><Link underline='hover' onClick={event => handleRowClick('effEndDatetime')} title="sort">Eff End Date</Link></TableCell>
                  <TableCell>Published</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { contentList.slice(0, rowsPerPage).map(travelContent => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={travelContent.id}
                    selected={selectedCampaigns.indexOf(travelContent.id) !== -1}
                    onClick={(e)=> {}}
                  >
                    <TableCell>{travelContent.version}</TableCell>
                    <TableCell>{moment(travelContent.createdOn).format('YYYY-MM-DD HH:mm:ss') }</TableCell>
                    <TableCell> {moment(travelContent.startDatetime).format('YYYY-MM-DD HH:mm:ss')} </TableCell>
                    <TableCell>{moment(travelContent.endDatetime).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                    <TableCell style={{fontSize:20}}>{travelContent.status==='active'? '✓' : ''}</TableCell>
                    <TableCell>
                    <Button variant="contained" style={{textTransform: 'capitalize'}}
                            onClick={event => handleViewClick(travelContent.id)}>View</Button>
                    {
                        travelContent.status==='inactive' && moment(travelContent.endDatetime)>new Date() &&(
                        <Button variant="contained" style={{marginLeft:20, textTransform: 'capitalize'}}
                                                    onClick={event => handlePublishClick(travelContent.id)}>Publish</Button>
                        )
                    }

                    {
                        travelContent.status==='active' && moment(travelContent.endDatetime)>new Date() &&(
                        <Button variant="contained" style={{marginLeft:20, textTransform: 'capitalize'}}
                                                    onClick={event => handleVersionClick(travelContent.id)}>Create a Version</Button>
                        )
                    }

                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
          </PerfectScrollbar>
        
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={totalRecords}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </CardActions>
    </Card>
  );
};

ContentTable.propTypes = {
  className: PropTypes.string,
  contentList: PropTypes.array.isRequired,
  totalRecords: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangePublish: PropTypes.func,
  defaultRowsPerPage: PropTypes.number.isRequired,
  defaultPage: PropTypes.number.isRequired
};

export default ContentTable;

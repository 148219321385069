class Configuration {

    MODULE_HRM = true;
    MODULE_ENTERPRISE = false;
    SERVICE_NAME_CAMPAIGN = "/campaign";
    SERVICE_ACTION_CAMPAIGN_SELECTION_FIELDS = "/selectionFields";
    SERVICE_ACTION_CAMPAIGN_LIST_FOR_TABLE = "/listForTableView";

    SERVICE_NAME_ANNOUNCEMENT = "/announcement";
    SERVICE_NAME_CONTENT = "/content";
    SERVICE_ACTION_LIST = "/list";
    SERVICE_ACTION_FIND = "/find";
    SERVICE_ACTION_PUBLISH = "/publish";
    SERVICE_ACTION_CREATE_VERSION = "/createVersion";

    // Cookies config
    COOKIE_NAME_TOKEN = "QQ-token";
    COOKIE_NAME_LOGIN_USER_NAME = "QQ-current-login";
    COOKIE_NAME_ROW_PER_PAGE = "rowPerPage";
    COOKIE_NAME_ROW_PER_PAGE_GRID = "rowPerPageGrid";
    DEFAULT_ROW_PER_PAGE = 10;
    DEFAULT_ROW_PER_PAGE_GRID = 20;
    COOKIE_NAME_ADMIN_DASHBOARD_HEADCOUNT_OPTION = "adminDashBoardHeadCountOpt";
    ////////////////////////////////////////////////////////////////////////////
    
    // messages / errors
    MESSAGE_REQUEST_TIMEOUT = 'Request timeout';
    MESSAGE_AUTH_FAILED = 'Authentication failed';
    ////////////////////////////////////////////////////////////////////////////
    SERVICE_NAME_LOGIN = '/v1/recogser/login/';
    SERVICE_NAME_EMPLOYEE = '/v1/employee';
    SERVICE_NAME_JOBTITLE = '/v1/job_titles';
    SERVICE_NAME_FACE_RECOGNITION = 'v1/recogser';
    SERVICE_NAME_DEPARTMENT = '/v1/department';
    SERVICE_ACTION_LIST_SEARCH = '/search';
    SERVICE_ACTION_RETRIEVE = '/retrieve';
    SERVICE_ACTION_EMPLOYEE_PHOTO = '/recognition/download_profile';
    SERVICE_ACTION_UPDATE = '/update';
    SERVICE_ACTION_ZIP = '/zip';
    SERVICE_ACTION_UPLOAD = '/upload';
    SERVICE_ACTION_IMAGE = '/img';
    SERVICE_NAME_ATTENDENCE = '/v1/attendance';
    SERVICE_ACTION_SUPERVISOR = '/supervisor';

    SERVICE_NAME_USER = '/v1/recogser/user';
    SERVICE_ACTION_INFO = '/info/';
    SERVICE_ACTION_UPDATE = '/update';
    SERVICE_ACTION_USER_PHOTO = '/recognition/list_photos';
    SERVICE_ACTION_USER_ROLE = '/user/roles';
    SERVICE_ACTION_EXPORT = '/export';

    SERVICE_NAME_LEAVE = '/v1/leave';
    SERVICE_ACTION_LEAVE_LIST = '/list';
    SERVICE_ACTION_LEAVE_CHANGE = '/change';

    SERVICE_NAME_LEAVE_TYPE = '/v1/leaveType';
    SERVICE_ACTION_LEAVE_TYPE = '/type';

    SERVICE_ACTION_USER_BRIEF = "/detail";

    SERVICE_NAME_ORGANIZATION = '/v1/recogser/organization';
    SERVICE_ACTION_ORGANIZATION_LIST_BY_FACE = '/list_by_face/';
    SERVICE_ACTION_ORGANIZATION_JOIN_BY_FACE = '/join_by_face';
    SERVICE_ACTION_EMPLOYEE_ACTIVE_DEFAULT ="/active_default";
    COOKIE_NAME_USER_DATA = "usr_curr";
    SERVICE_ACTION_USER_ROLE_CHECK ="/role_check";
    COOKIE_NAME_AUTH = "QQ_auth";
    SERVICE_ACTION_LEAVE_APPLY = '/apply';
    SERVICE_ACTION_LEAVE_BALANCE = '/balance/';
    SERVICE_ACTION_LEAVE_DETAIL = '/detail/';
    SERVICE_ACTION_LEAVE_TYPE_CREATE = '/create';
    SERVICE_ACTION_LEAVE_TYPE_UPDATE = '/update';
    SERVICE_ACTION_LEAVE_TYPE_DELETE = '/delete/';

    SERVICE_NAME_LEAVE_PERIOD = '/v1/leavePeriod';
    SERVICE_ACTION_LEAVE_PERIOD_LIST = '/list/';
    SERVICE_ACTION_LEAVE_PERIOD_CREATE = '/create';
    SERVICE_ACTION_LEAVE_PERIOD_UPDATE = '/update';
    SERVICE_ACTION_LEAVE_PERIOD_DELETE = '/delete/';

    SERVICE_NAME_LEAVE_PTO = '/v1/pto';
    SERVICE_ACTION_LEAVE_PTO_LIST = '/list/';
    SERVICE_ACTION_LEAVE_PTO_CREATE = '/create';
    SERVICE_ACTION_LEAVE_PTO_UPDATE = '/update';
    SERVICE_ACTION_LEAVE_PTO_DELETE = '/delete/';

    SERVICE_NAME_LEAVE_GROUP = '/v1/leaveGroup';
    SERVICE_ACTION_LEAVE_GROUP_LIST = '/list/';
    SERVICE_ACTION_LEAVE_GROUP_CREATE = '/create';
    SERVICE_ACTION_LEAVE_GROUP_UPDATE = '/update';
    SERVICE_ACTION_LEAVE_GROUP_DELETE = '/delete/';

    SERVICE_NAME_LEAVE_GROUP_EMPLOYEE = '/v1/leaveGroupEmployee';
    SERVICE_ACTION_LEAVE_GROUP_EMPLOYEE_LIST = '/list/';
    SERVICE_ACTION_LEAVE_GROUP_EMPLOYEE_CREATE = '/create';
    SERVICE_ACTION_LEAVE_GROUP_EMPLOYEE_UPDATE = '/update';
    SERVICE_ACTION_LEAVE_GROUP_EMPLOYEE_DELETE = '/delete/';

    SERVICE_NAME_SALARY_COMPONENT_TYPE = "/v1/salaryComponentType";
    SERVICE_ACTION_SALARY_COMPONENT_TYPE_LIST = "/list/";
    SERVICE_ACTION_SALARY_COMPONENT_TYPE_CREATE = "/create";
    SERVICE_ACTION_SALARY_COMPONENT_TYPE_UPDATE = "/update";
    SERVICE_ACTION_SALARY_COMPONENT_TYPE_DELETE = "/delete/";

    SERVICE_NAME_SALARY_COMPONENT = "/v1/salaryComponent";
    SERVICE_ACTION_SALARY_COMPONENT_LIST = "/list/";
    SERVICE_ACTION_SALARY_COMPONENT_CREATE = "/create";
    SERVICE_ACTION_SALARY_COMPONENT_UPDATE = "/update";
    SERVICE_ACTION_SALARY_COMPONENT_DELETE = "/delete/";

    SERVICE_NAME_EMPLOYEE_SALARY = "/v1/employeeSalary";
    SERVICE_ACTION_EMPLOYEE_SALARY_LIST = "/list";
    SERVICE_ACTION_EMPLOYEE_SALARY_CREATE = "/create";
    SERVICE_ACTION_EMPLOYEE_SALARY_UPDATE = "/update";
    SERVICE_ACTION_EMPLOYEE_SALARY_DELETE = "/delete/";

    SERVICE_NAME_DEDUCTION = "/v1/deduction";
    SERVICE_ACTION_DEDUCTION_LIST = "/list/";
    SERVICE_ACTION_DEDUCTION_CREATE = "/create";
    SERVICE_ACTION_DEDUCTION_UPDATE = "/update";
    SERVICE_ACTION_DEDUCTION_DELETE = "/delete/";
    SERVICE_ACTION_DEDUCTION_DETAILS = "/detail/";

    SERVICE_NAME_DEDUCTION_GROUP = "/v1/deductionGroup";
    SERVICE_ACTION_DEDUCTION_GROUP_LIST = "/list/";
    SERVICE_ACTION_DEDUCTION_GROUP_CREATE = "/create";
    SERVICE_ACTION_DEDUCTION_GROUP_UPDATE = "/update";
    SERVICE_ACTION_DEDUCTION_GROUP_DELETE = "/delete/";

    SERVICE_NAME_PAYROLL_COLUMN = "/v1/payrollColumn";
    SERVICE_ACTION_PAYROLL_COLUMN_LIST = "/list/";
    SERVICE_ACTION_PAYROLL_COLUMN_CREATE = "/create";
    SERVICE_ACTION_PAYROLL_COLUMN_UPDATE = "/update";
    SERVICE_ACTION_PAYROLL_COLUMN_DELETE = "/delete/";
    SERVICE_ACTION_PAYROLL_COLUMN_DETAILS = "/detail/";
    SERVICE_ACTION_PAYROLL_COLUMN_DATA_SOURCE = "/dataSource";

    SERVICE_NAME_PAYSLIP_TEMPLATE = "/v1/payslipTemplate";
    SERVICE_ACTION_PAYSLIP_TEMPLATE_LIST = "/list/";
    SERVICE_ACTION_PAYSLIP_TEMPLATE_CREATE = "/create";
    SERVICE_ACTION_PAYSLIP_TEMPLATE_UPDATE = "/update";
    SERVICE_ACTION_PAYSLIP_TEMPLATE_DELETE = "/delete/";
    SERVICE_ACTION_PAYSLIP_TEMPLATE_DETAILS = "/detail/";

    SERVICE_NAME_PAY_FREQUENCY = "/v1/payFrequency";
    SERVICE_ACTION_PAY_FREQUENCY_LIST = "/list";

    SERVICE_NAME_PAYROLL_EMPLOYEE = "/v1/payrollEmployee";
    SERVICE_ACTION_PAYROLL_EMPLOYEE_LIST = "/list";
    SERVICE_ACTION_PAYROLL_EMPLOYEE_CREATE = "/create";
    SERVICE_ACTION_PAYROLL_EMPLOYEE_UPDATE = "/update";
    SERVICE_ACTION_PAYROLL_EMPLOYEE_DELETE = "/delete/";
    SERVICE_ACTION_PAYROLL_EMPLOYEE_DETAILS = "/detail/";


    SERVICE_NAME_PAYROLL = "/v1/payroll";
    SERVICE_ACTION_PAYROLL_LIST = "/list/";
    SERVICE_ACTION_PAYROLL_CREATE = "/create";
    SERVICE_ACTION_PAYROLL_UPDATE = "/update";
    SERVICE_ACTION_PAYROLL_DELETE = "/delete/";
    SERVICE_ACTION_PAYROLL_DETAILS = "/detail/";
    SERVICE_ACTION_PAYROLL_REPORT = "/view/";

    SERVICE_NAME_SKILLS = '/v1/skill';
    SERVICE_NAME_EDUCATION = '/v1/education';
    SERVICE_NAME_CERTIFICATIONS = '/v1/certification';
    SERVICE_ACTION_CREATE = '/create';
    SERVICE_ACTION_DELETE = '/delete';

    SERVICE_NAME_EMPLOYEE_SKILLS = '/v1/employeeSkill';
    SERVICE_NAME_EMPLOYEE_CERTIFICATIONS  = '/v1/employeeCertification';
    SERVICE_NAME_EMPLOYEE_EDUCATIONS  = '/v1/employeeEducation';
    SERVICE_NAME_NATIONALITY = '/v1/nationality';
    SERVICE_ACTION_NATIONALITY_LIST = '/nationalityList';
    SERVICE_NAME_COUNTRY= '/v1/country';
    SERVICE_ACTION_COUNTRY_LIST = '/countryList';
    SERVICE_NAME_COUNTRY= '/v1/country';
    SERVICE_ACTION_COUNTRY_LIST = '/countryList';
    SERVICE_NAME_PROVINCE = '/v1/province';
    SERVICE_ACTION_PROVINCE_LIST = '/provinceList';
    SERVICE_NAME_DEPENDENT = '/v1/employeeDependent';
    SERVICE_NAME_EMERGENCY_CONTACT = '/v1/emergencyContacts';


    SERVICE_NAME_NATIONALITY  = '/v1/nationality';
    SERVICE_ACTION_NATIONALITY_LIST = "/nationalityList";

    SERVICE_NAME_PAY_GRADE  = '/v1/payrollGrade';
    SERVICE_ACTION_PAY_GRADE_LIST = "/list/";

    SERVICE_NAME_PROVINCE  = '/v1/province';
    SERVICE_ACTION_PROVINCE_LIST = "/provinceList";

    SERVICE_NAME_COUNTRY  = '/v1/country';
    SERVICE_ACTION_COUNTRY_LIST = "/countryList";

    SERVICE_ACTION_EMPLOYEE_WEB_CREATE = "/create_with_detail";

    SERVICE_NAME_FILES  = '/v1/files';
    SERVICE_ACTION_FILES_UPLOAD_TMP = "/tmp_file_upload";
    SERVICE_ACTION_FILES_DOWNLOAD_TMP = "/tmp_file_download/";

    SERVICE_ACTION_FACE_RECOGNITION_DETECT_FACE = "/recognition/detect";
    SERVICE_ACTION_FACE_RECOGNITION_UPDATE_PROFILE = "/recognition/upload_profile";
    SERVICE_ACTION_FACE_RECOGNITION_UPDATE_PHOTOS = "/recognition/upload_photos";
    SERVICE_ACTION_USER_CHANGE_PASSWORD = "/change_password";


    SERVICE_NAME_DASHBOARD  = '/v1/dashboard';
    SERVICE_ACTION_DASHBOARD_AbsenteeismRate = "/absenteeism_rate/";
    SERVICE_ACTION_DASHBOARD_AbsenteeismByDateRange = "/absenteeism_by_date_range";
    SERVICE_ACTION_DASHBOARD_HireTerminateByDateRange = "/hire_terminate_by_date_range";
    SERVICE_ACTION_DASHBOARD_HeadcountByDepartments = "/headcount_by_departments/";
    SERVICE_ACTION_DASHBOARD_GenderRatio = "/gender_ratio/";
    SERVICE_ACTION_DASHBOARD_LeaveRatio = "/leave_ratio/";
    SERVICE_ACTION_DASHBOARD_AverageLeaveBalance = "/average_leave_balance/";
    SERVICE_ACTION_DASHBOARD_LeaveByDepartment = "/leave_by_department";
    SERVICE_ACTION_DASHBOARD_TotalOTCurrentYear = "/total_ot_current_year/";
    SERVICE_ACTION_DASHBOARD_TopTenOTEmployees = "/top_ten_ot_employees/";
    SERVICE_ACTION_DASHBOARD_AttendanceAndLeave = "/attendance_and_leave/";
    SERVICE_ACTION_DASHBOARD_AttendanceAndLeaveMultiLevels = "/attendance_leave_multi_levels";
    SERVICE_ACTION_DASHBOARD_EmployeeCount = "/employee_count";
    SERVICE_ACTION_DASHBOARD_LeaveCount = "/leave_count";
    SERVICE_ACTION_DASHBOARD_AttendanceCount = "/attendance_count";
    SERVICE_ACTION_DASHBOARD_EMPLOYEE_LeaveBalance = "/employee_leave_balance";
    SERVICE_ACTION_DASHBOARD_AttendanceLeaveEmployee = "/employee_attendance_leave";
    SERVICE_ACTION_DASHBOARD_OTSumByEmployee = "/employee_ot_sum";

    SERVICE_NAME_HOLIDAYS  = '/v1/holiday';
    SERVICE_ACTION_HOLIDAYS_UPCOMING  = '/upcoming';
    SERVICE_ACTION_EMPLOYEE_DUTY = '/employee_duty';
    SERVICE_ACTION_EDIT = '/edit';


    SERVICE_NAME_REPORTS  = '/v1/report';
    SERVICE_ACTION_REPORTS_VIEW_ADMIN_REPORT  = '/view_admin_report';
    SERVICE_ACTION_REPORTS_DOWNLOAD_ADMIN_REPORT  = '/download_admin_report';
    SERVICE_NAME_WORKDAYS ='/v1/workDay';

    SERVICE_ACTION_PAYROLL_REPORT_DOWNLOAD = "/download_report";
    SERVICE_ACTION_EMPLOYEE_TERMINATE = "/terminate/";


    SERVICE_NAME_EMPLOYEE_DUTY  = '/v1/employee_duty';
    SERVICE_ACTION_EMPLOYEE_DUTY_CREATE = "/create";
    SERVICE_ACTION_EMPLOYEE_DUTY_UPDATE = "/update";
    SERVICE_ACTION_EMPLOYEE_DUTY_LIST = "/list/";
    SERVICE_ACTION_PAYROLL_LIST_BY_EMPLOYEE = "/list_by_employee/";
    SERVICE_ACTION_PAYROLL_DOWNLOAD_PAYSLIP = "/download_payslip/";

    SERVICE_ACTION_PAY_GRADE_CREATE = "/create";
    SERVICE_ACTION_PAY_GRADE_UPDATE = "/update";
    SERVICE_ACTION_PAY_GRADE_DELETE = "/delete/";

    COOKIE_NAME_DEVICE_TOKEN = "D-token";
    SERVICE_ACTION_USERNAME_CHECK = "/user/available/";
    SERVICE_ACTION_ORGANIZATION_REGISTER = "/organization/register";
    SERVICE_ACTION_ORGANIZATION_VALIDATE_OTP = "/organization/validate_otp";
    SERVICE_ACTION_ORGANIZATION_RESEND_OTP = "/organization/resend_otp";
    SERVICE_ACTION_FACE_REGISTER = "/recognition/register";
    SERVICE_ACTION_USER_REGISTER = "/user/create";
    SERVICE_ACTION_ORGANIZATION_UPLOAD_LOGO = "/upload_logo";
    SERVICE_ACTION_ORGANIZATION_UPDATE = "/update";
    REGISTER_ORGANIZATION_PLAN = "Pro";
    DEFAULT_ORGANIZATION_ROLE_GUID = "3c442251-3ede-45ab-9343-c30a89a97dba";

    SERVICE_NAME_FPT_USER  = '/v1/ftp_user';
    SERVICE_ACTION_FPT_USER_CREATE = "/create";
    SERVICE_ACTION_FPT_USER_UPDATE = "/update";
    SERVICE_ACTION_FPT_USER_STATUS = "/change_status";
    SERVICE_ACTION_FPT_USER_LIST = "/list/";
    SERVICE_ACTION_FPT_USER_DELETE = "/delete/";

    SERVICE_NAME_SYSTEM_SETTINGS  = '/v1/hr_settings';
    SERVICE_ACTION_SYSTEM_SETTINGS_LIST = "/list/";
    SERVICE_ACTION_SYSTEM_SETTINGS_UPDATE = "/update";


    COOKIE_NAME_LANGUAGE = "HRM-Language";
    // Enterprise Gates Vision
    SERVICE_NAME_SUSPICIOUS  = '/v1/suspicious';
    SERVICE_NAME_SUSPICIOUS_CHANGE_STATUS  = '/change_status/';
    SERVICE_NAME_SUSPICIOUS_DOWNLOAD_LIST  = '/download_list/';
    SERVICE_NAME_SUSPICIOUS_LIST_MATCHED_LOGS  = '/list_matched_logs/';
    SERVICE_NAME_SUSPICIOUS_DOWNLOAD_LIST_MATCHED_LOGS  = '/download_matched_logs/';
    SERVICE_NAME_SUSPICIOUS_REGISTER  = '/register';
    SERVICE_NAME_SUSPICIOUS_UPDATE  = '/update';
    SERVICE_NAME_CAMERA  = '/v1/camera';
    SERVICE_NAME_CAMERA_DOWNLOAD_LAST_IMAGE_BASE64  = '/download_last_image_base64/';
    SERVICE_NAME_CAMERA_LIST_PREVIEW  = '/list_preview/';
    SERVICE_NAME_CAMERA_LIST_VISITOR_LOG_RECORDS  = '/list_visitor_log_records/';
    SERVICE_NAME_CAMERA_LIST_OBJECT_LOG_RECORDS  = '/list_object_log_records/';
    SERVICE_NAME_CAMERA_DOWNLOAD_LOG_IMAGE  = '/download_log_image/';
    SERVICE_NAME_CAMERA_LIST_CAMERA_RECORDS  = '/list_camera_records/';
    SERVICE_NAME_CAMERA_CHANGE_STATUS  = '/change_status/';
    SERVICE_NAME_CAMERA_REGISTER  = '/register';
    SERVICE_NAME_CAMERA_UPDATE  = '/update';
    SERVICE_NAME_CAMERA_FTP_INFO  = '/camera_ftp_info/';
    SERVICE_NAME_FTP_USER  = '/v1/ftp_user';
    SERVICE_NAME_FTP_USER_UPDATE  = '/update';
    SERVICE_NAME_PERSON = '/v1/person';
    SERVICE_NAME_PERSON_DETAIL = '/detail/';
}
export default Configuration;